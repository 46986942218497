import React from 'react';

const Error = () => {
  return (
    <div>
      <div className='text-center'>
        <h2>404 | Not Found</h2>
        <p>La página que estás buscando no existe.</p>
      </div>
    </div>
  );
};

export default Error;
